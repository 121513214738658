import { Auth } from 'aws-amplify';
import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

// import Dashboard from '@/views/user/Dashboard.vue';

import Login from '@/views/Login.vue';

import { userIsAuthorised, UserRole } from '@/util/auth';
import { apiGet } from '@/util/api';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'frontpage',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { role: UserRole.LOGGED_OUT },
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: Dashboard,
  //   meta: { role: UserRole.USER },
  //   beforeEnter: (to, from, next) => {
  //     store.commit('setLoading', 'wheel');
  //     next();
  //   },
  // },
  {
    path: '/forgot-password',
    name: 'forgotpassword',
    component: () => import('../views/ForgotPassword.vue'),
    meta: { role: UserRole.LOGGED_OUT },
  },
  // {
  //   path: '/inspiration',
  //   name: 'inspiration',
  //   component: () => import('../views/user/Inspiration.vue'),
  //   props: route => ({
  //     category: route.query.category,
  //     genre: route.query.genre,
  //     country: route.query.country,
  //   }),
  //   meta: { role: UserRole.USER },
  // },
  // {
  //   path: '/inspiration/recommended',
  //   name: 'recommended',
  //   component: () => import('../views/user/Recommended.vue'),
  //   props: route => ({
  //     params: route.params,
  //   }),
  //   meta: { role: UserRole.USER },
  // },
  // {
  //   path: '/inspiration/bookmarks',
  //   name: 'bookmarks',
  //   component: () => import('../views/user/Bookmarks.vue'),
  //   meta: { role: UserRole.USER },
  // },
  // {
  //   path: '/glossary',
  //   name: 'glossary',
  //   component: () => import('../views/user/Glossary.vue'),
  //   meta: { role: UserRole.USER },
  // },
  {
    path: '/policy',
    name: 'policy',
    component: () =>
      import(/* webpackChunkName: "policy" */ '../views/Policy.vue'),
    meta: { role: UserRole.ANY },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/user/Profile.vue'),
    meta: { role: UserRole.USER },
  },
  // {
  //   path: '/queries',
  //   name: 'queries',
  //   component: () =>
  //     import(/* webpackChunkName: "queries" */ '../views/user/Queries.vue'),
  //   meta: { role: UserRole.USER },
  // },
  {
    path: '/superadmin/voices',
    name: 'voices',
    component: () =>
      import(/* webpackChunkName: "voices" */ '../views/superadmin/Voices.vue'),
    meta: { role: UserRole.SUPERADMIN },
  },
  {
    path: '/superadmin/clients',
    name: 'clients',
    component: () =>
      import(
        /* webpackChunkName: "clients" */ '../views/superadmin/Clients.vue'
      ),
    meta: { role: UserRole.SUPERADMIN },
  },
  {
    path: '/superadmin/client-new',
    name: 'client-new',
    component: () =>
      import(
        /* webpackChunkName: "clientnew" */ '../views/superadmin/ClientNew.vue'
      ),
    meta: { role: UserRole.SUPERADMIN },
  },
  {
    path: '/superadmin/client-edit/:clientId/:created?',
    name: 'client-edit',
    component: () =>
      import(
        /* webpackChunkName: "clientedit" */ '../views/superadmin/ClientEdit.vue'
      ),
    meta: { role: UserRole.SUPERADMIN },
  },
  {
    path: '/superadmin/reports',
    name: 'reports',
    component: () =>
      import(
        /* webpackChunkName: "reports" */ '../views/superadmin/Reports.vue'
      ),
    meta: { role: UserRole.SUPERADMIN },
  },
  {
    path: '/superadmin/surveys',
    name: 'surveys',
    component: () =>
      import(
        /* webpackChunkName: "surveys" */ '../views/superadmin/Surveys.vue'
      ),
    meta: { role: UserRole.SUPERADMIN },
  },
  {
    path: '/superadmin/survey/:surveyId?',
    name: 'survey',
    component: () =>
      import(/* webpackChunkName: "survey" */ '../views/superadmin/Survey.vue'),
    meta: { role: UserRole.SUPERADMIN },
  },
  {
    path: '/superadmin/users',
    name: 'users',
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/superadmin/Users.vue'),
    meta: { role: UserRole.SUPERADMIN },
  },
  {
    path: '/superadmin/user-new/:clientId?',
    name: 'user-new',
    component: () =>
      import(
        /* webpackChunkName: "usernew" */ '../views/superadmin/UserNew.vue'
      ),
    meta: { role: UserRole.SUPERADMIN },
  },
  {
    path: '/superadmin/user-edit/:userId/:created?',
    name: 'user-edit',
    component: () =>
      import(
        /* webpackChunkName: "useredit" */ '../views/superadmin/UserEdit.vue'
      ),
    meta: { role: UserRole.SUPERADMIN },
  },
  // {
  //   // TODO - needs filtering parameters
  //   path: '/curator/contents',
  //   name: 'contents',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "contents" */ '../views/curator/Contents.vue'
  //     ),
  //   meta: { role: UserRole.CURATOR },
  // },
  // {
  //   path: '/curator/content/:contentId?',
  //   name: 'content',
  //   props: route => ({
  //     contentId: route.params.contentId,
  //   }),
  //   component: () =>
  //     import(/* webpackChunkName: "content" */ '../views/curator/Content.vue'),
  //   meta: { role: UserRole.CURATOR },
  // },
  // {
  //   path: '/curator/tagging',
  //   name: 'taggingSummary',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "taggingSummary" */ '../views/curator/TaggingSummary.vue'
  //     ),
  //   meta: { role: UserRole.CURATOR },
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Always allow nav to frontpage
  if (to.name === 'frontpage') {
    next();
    return;
  }

  // Attempt to ensure that we have a valid user session.
  if (store.state.user.id) {
    // Check that we have both a user in the store and an active Amplify session. if not, return to the login page.
    await Auth.currentSession().catch(() => {
      store.commit('clearUser');
      if (to.name !== 'login') {
        next({ name: 'login' });
      }
    });
  } else {
    // Attempt to get the currently logged in user from the back end if no user is set (e.g. if page refreshed).
    await apiGet('/auth/me')
      .then(user => {
        store.commit('setUser', user);
      })
      .catch(async () => {
        try {
          // Note this is very similar to code in components/Header.js
          await Auth.signOut();
          store.commit('clearUser');
          if (![UserRole.ANY, UserRole.LOGGED_OUT].includes(to.meta.role)) {
            next({ name: 'login' });
          }
        } catch (error) {
          console.error('Error clearing session: ', error);
        }
      });
  }

  // Check whether the user is authorised for the target route.
  // Unauthorised users are sent to the dashboard or the login page, depending on whether they are logged in.

  // Special logic for curators: prevent access to dashboard and queries page
  if (store.getters.userIsCuratorOnly) {
    if (['dashboard', 'queries'].includes(to.name)) {
      next({ name: 'inspiration', query: { category: 'beautiful' } });
      return;
    }
  }

  if (userIsAuthorised(to.meta.role)) {
    next();
  } else {
    if (store.state.user.userId) {
      if (to.name !== 'voices') {
        next({ name: 'voices' });
      }
    } else {
      if (to.name !== 'login') {
        next({ name: 'login' });
      }
    }
  }
});

export default router;
