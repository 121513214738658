/**
 * Common header.
 */
import { userIsCurator, userIsSuperadmin } from '@/util/auth';
import { Auth } from 'aws-amplify';

export default {
  name: 'Header',
  computed: {
    /**
     * Determines whether the user is a curator.
     */
    isCurator() {
      return userIsCurator();
    },
    /**
     * Determines whether the user is a superadmin.
     */
    isSuperadmin() {
      return userIsSuperadmin();
    },
    currentPage() {
      return this.$route.name;
    },
  },
  data: () => ({
    pages: [
      'voices',
      'clients',
      'users',
      'surveys',
      'reports',
    ]
  }),
  methods: {
    /**
     * Log the user out.
     * Once logged out, redirects them to the login page.
     */
    async doLogout() {
      // Note this is very similar to code in router/index.js
      try {
        await Auth.signOut();
        this.$store.commit('clearUser');
        this.$router.push({ name: 'login' }).catch(() => { });
      } catch (error) {
        console.error('Error signing out: ', error);
      }
    },

    /**
     * Navigate to the target route.
     * @param {String} target
     */
    async goToTarget(target) {
      this.$router.push({ name: target }).catch(() => { });
    },
  },
};
